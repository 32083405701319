<template>
  <v-text-field
    :placeholder="placeholder"
    append-icon="mdi-magnify"
    style="max-width: 450px"
    hide-details
    clearable
    rounded
    dense
    solo
  ></v-text-field>
</template>

<script>

export default {
  name: 'Search',

  props: {
    placeholder: String
  }
}

</script>
